import { camelizeKeys, decamelizeKeys } from 'humps'
import { parseError } from '../parseError'
import { getUserCookieValues, logout } from '../userAuth'
import { getApiUrl, getDefaultBackendUrl } from './apiHelpers'
import { getParameterString } from './getParameterString'
import 'whatwg-fetch'

export const ENDPOINTS = {
  shipments: 'v8/shipments',
  auctionShipments: 'v1/auction_shipments',
  publicShipments: 'v5/public_shipments',
  shippers: 'v1/shippers',
  carriers: 'v6/carriers',
  carrierLanePreferences: 'v6/carrier_lane_preferences',
  users: 'v5/users',
  v7Users: 'v7/users',
  parentUsers: 'v7/parent_users',
  password: 'v1/users/password',
  bids: 'v5/bids',
  search: 'v3/search',
  translate: 'v1/translate',
  detect: 'v1/detect',
  notes: 'v5/notes',
  manageLanes: 'v5/dat_market_lanes',
  quotes: 'v5/quotes',
  vendorBills: 'v1/vendor_bills',
  priceRequests: 'v1/price_requests',
  updatePriceRequest: 'v6/update_price_request',
  billComPayments: 'v1/bill_com_payments',
  allTrucks: 'v1/carrier_available_trucks',
  laneMatchTriggers: 'v1/lane_match_triggers',
  contractLanes: 'v5/carrier_contract_lanes',
  contractLaneMatches: 'v5/contract_lane_matches',
  getConfig: 'v1/spot_margin_config/get_config',
  saveConfig: 'v1/spot_margin_config/update_config',
  quotingAllJob: 'v1/ab_shipment_quoting_all_job/',
  bidStrategy: '/v5/get_bid_strategy/',
  brokerCarrierAgreement: 'v2/broker_carrier_agreements',
  emailBid: 'v5/email_bid',
  linkShipment: 'v5/quotes/link_shipment',
  saferCarrierLookup: 'v5/carriers/search_safer',
  csvBid: 'v5/csv_bid',
  autodialCampaigns: 'v5/autodial_campaigns',
  ceilingPrice: 'v5/ceiling_price_adjustments',
  signIn: 'v7/users/sign_in',
  signOut: 'v7/users/sign_out',
  token: 'v7/token',
  requestRate: 'v5/surge_shipper_rate',
  shipmentDrafts: 'v5/shipment_drafts',
  acceptRate: 'v5/surge_accept_shipper_rate',
  retrieveRate: 'v5/surge_retrieve_shipper_rate',
  shipperSignUp: '/v5/users/shipper_signup',
  shipperOnboarding: 'tms/v5/shipper_onboarding_form_submissions',
  getOnboardingInfo: 'tms/v5/shipper_onboarding_form_submissions',
  editOnboardingInfo: 'tms/v5/shipper_onboarding_form_submissions',
  watchedLanesPriceRequests: 'v5/shipper_watched_lanes_price_requests',
  versions: 'v1/versions',
  sms: 'v1/sms',
  facilities: 'v5/facilities',
  v3Facilities: 'v3/facilities',
  v9Facilities: 'v9/facilities',
  shipmentStops: 'v5/shipment_stops',
  transfixLanes: 'v5/transfix_lanes',
  laneManagerAssignments: 'v5/lane_manager_assignments',
  laneMetrics: 'v6/lane_metrics',
  dashboardShipments: 'v6/tms/dashboard_shipments',
  dashboardMetrics: 'v6/tms/dashboard_metrics',
  ceTasks: 'v8/ce_tasks',
  shipmentCount: 'v8/shipments/count',
  shipmentBulkAssignCm: 'v5/shipments/bulk_assign_cm',
  laneMatchedCarriers: 'v5/lane_matched_carriers',
  ceManagers: 'v6/ce_managers/update_ce_availability',
  shipperRates: 'v9/shipper_rates',
  shipperLanes: 'v9/shipper_lanes',
  spotBiddingRules: 'v6/spot_bidding_rules',
  spotBiddingConfigs: 'v6/spot_bidding_configs',
  globalSpotConfig: 'v9/global_spot_bidding_config',
  costControlConfigs: 'v8/cost_control_configs',
  v9Shippers: 'v9/shippers',
  cxTasks: 'v9/cx_tasks',
  cxSchedules: 'v9/cx_schedules',
  shipperSpotRates: 'v6/shipper_spot_rates',
  bulkSpotRates: 'v6/bulk_spot_rates',
  featureFlags: 'flags',
  ediFiles: 'v5/edi_files',
  ediFilesMapper: 'v5/edi_tools/raw_edi_files',
  ediSelfServeInbound: 'v6/edi_tools/self_serve_ui/inbound_test',
  ediSelfServeRunParser: 'v6/edi_tools/self_serve_ui/run_parser',
  ediSelfServeConfigs: 'v6/edi_tools/self_serve_ui/inbound_config',
  ediSelfServeParsers: 'v6/edi_tools/configurable_parsers',
  ediSelfServeParserVersions: 'v6/edi_tools/configurable_parser_versions',
  ediSelfServeTests: 'v6/edi_tools/configurable_parser_tests',
  ediSelfServeBase: 'v6/edi_tools/self_serve_ui',
  updatePassword: 'v9/parent_users/update_password',
  instantRfp: 'v9/instant_rfp/generate_norm_file',
  storeRfpPrices: 'v9/instant_rfp/store_prices',
  storeRfpAward: 'v9/instant_rfp/store_award',
  commodities: 'v5/commodities',
  rfpLaneRates: 'v9/request_for_proposal_lane_carrier_rates',
  rfpLanes: 'v9/request_for_proposal_lanes',
  requestForProposals: 'v9/request_for_proposals',
  rescheduleReasons: 'v5/reschedule_reasons',
  shipmentAccessorials: 'v8/shipment_accessorials',
  fleetPlannerShipments: 'fleet_planner/v1/shipments',
  fleetPlannerCarriers: 'fleet_planner/v1/carriers',
  fleetPlannerUsers: 'fleet_planner/v1/users',
  fleetPlannerBids: 'fleet_planner/v1/bids',
  fleetPlannerAuctionShipments: 'fleet_planner/v1/auction_shipments',
  fleetPlannerShipmentOffers: 'fleet_planner/v1/offered_shipments',
  fleetPlannerShipmentOffersDecline: 'fleet_planner/v1/contract_lane_matches',
  tmsShipperReports: 'tms/v1/shipper_qbr_reports.json',
  bumbleBeeReport: 'v1/bumble_bee_food_report.json',
  accounting: 'v8/accounting',
}

export const TMS_ENDPOINTS = {
  auctions: 'v1/auctions',
  shipperSettings: 'v1/shippers/settings',
  shipperCarriers: 'v3/shipper_carriers',
  shipments: 'v1/shipments',
  shipmentsV2: 'v2/shipments',
  users: 'v1/users',
}

export const MAP_ENDPOINTS = {
  places: 'geocoding/search',
}

/*
  callApi ends up in the bundle for mobile,
  but this env variable is only set for web.
  fallback for mobile.
*/

window.__FP_API__ = window.__FP_API__ || ''

export const headers = includeXHeaderUserId => {
  const { authToken, deviceIdentifier, email, userId } = getUserCookieValues()

  return new Headers({
    'Content-Type': 'application/json',
    'x-user-email': email,
    'x-user-token': authToken,
    'x-rogers-revision': __ROGERS_VERSION__,
    ...(includeXHeaderUserId ? { 'x-user-id': userId } : {}),
    ...(deviceIdentifier ? { 'Device-Identifier': `cookie_uuid ${deviceIdentifier}` } : {}),
  })
}

const requestMethod = (method = '') => {
  switch (method.toLowerCase()) {
    case 'delete':
      return 'DELETE'
    case 'create':
    case 'put':
      return 'PUT'
    case 'patch':
      return 'PATCH'
    case 'update':
    case 'post':
      return 'POST'
    default:
      return 'GET'
  }
}

export const jsonifyBody = (body = {}, options = {}) => {
  const { shouldFormatRequestObj = true } = options || {}

  if (shouldFormatRequestObj) {
    return new Blob([JSON.stringify(decamelizeKeys(body))], {
      type: 'application/json',
    })
  }

  // for cases where we want the request body as unaltered json (i.e don't decamelize keys)
  return new Blob([JSON.stringify(body)], {
    type: 'application/json',
  })
}

export const createRequestObj = (requestData, options = {}) => {
  const { method, body, includeXHeaderUserId = true, other = {} } = requestData

  const requestObject = {
    method: requestMethod(method),
    headers: headers(includeXHeaderUserId),
    mode: 'cors',
    cache: 'default',
    // Allows us to specify other things in the request,
    // such as credentials or overwriting the headers.
    ...other,
  }
  const requestNeedsBody = !['GET', 'DELETE'].includes(requestMethod(method))
  if (requestNeedsBody) requestObject.body = jsonifyBody(body, options)
  return requestObject
}

export const createFullUrl = requestData => {
  const { endpoint, group, id, params } = requestData
  if (!endpoint) throw new Error('You forgot to include an endpoint in your API call!')

  // Explicit full urls (should rarely be used).
  if (endpoint.url) return endpoint.url

  // Order is important here

  let fullUrl

  if (Object.values(ENDPOINTS).includes(endpoint) || ENDPOINTS[endpoint]) {
    fullUrl = `${getApiUrl('MARKETPLACE')}/${endpoint}`
  } else if (Object.values(TMS_ENDPOINTS).includes(endpoint)) {
    fullUrl = `${getApiUrl('TMS')}/${endpoint}`
  } else if (Object.values(MAP_ENDPOINTS).includes(endpoint)) {
    fullUrl = `${getDefaultBackendUrl('MARKETPLACE')}/${endpoint}`
  } else {
    fullUrl = endpoint
  }

  if (id) fullUrl = `${fullUrl}/${id}`
  if (group) fullUrl = `${fullUrl}/${group}`
  if (params) fullUrl = `${fullUrl}${getParameterString(decamelizeKeys(params))}`
  return fullUrl
}

export const callApi = (requestData = {}, options = {}) => {
  const { shouldCamelizeKeys = true } = options || {}
  // Generic request object
  const url = createFullUrl(requestData)
  const request = createRequestObj(requestData, options)
  // Extra promise to properly handle network errors
  return new Promise((resolve, reject) => {
    // Call that endpoint!
    fetch(url, request)
      .then(response => {
        const urlObj = new URL(response.url)
        if (!response.ok) {
          const needsLogout =
            response.status === 401 &&
            urlObj.hostname !== 'api.mapbox.com' &&
            !urlObj.pathname.includes('/v1/shared/shipments') &&
            !urlObj.pathname.includes('/v7/users/sign_in')
          if (needsLogout) return logout({ origin: requestData.app })
          if (response.status === 404) return reject(String(Error('API route not found.')))
          if (response.status >= 500)
            return reject(String(Error('Server error. Please try again in a few minutes.')))
          return response.json().then(data => {
            const errorString = requestData.skipErrorPrefix
              ? parseError(data)
              : Error(parseError(data))
            return reject(String(errorString))
          })
        }

        return response
          .json()
          .then(resp => {
            let data = resp
            if (requestData.dataKey) {
              data = resp[requestData.dataKey]
            }
            if (shouldCamelizeKeys) {
              resolve(camelizeKeys(data))
            } else {
              resolve(data)
            }
          })
          .catch(data => resolve(data))
      })
      .catch(reason => {
        reject(
          String(
            Error(
              `${
                reason && reason.message ? reason.message : reason
              }. Please try again in a few minutes.`
            )
          )
        )
      })
  })
}

export default callApi
